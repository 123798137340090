import Layout from "./components/Layout.js";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
import Home from "./pages/Index";
import Reporting from "./pages/Features/Reporting";
import Shifts from "./pages/Features/Shifts";
import Vendors from "./pages/Features/Vendors";
import FeaturesHome from "./pages/Features/Home";
import Accountability from "./pages/Features/Accountability";
import ChecklistsAndTasks from "./pages/Features/Checklists-and-Tasks";
import Waste from "./pages/Features/Waste";
import Setups from "./pages/Features/Setups";
import Chat from "./pages/Features/Chat";
import Training from "./pages/Features/Training";
import Pricing from "./pages/Pricing";

import TeamAndScoreboard from "./pages/Features/Team-and-Scoreboard";
import Rewards from "./pages/Features/Rewards";
import Meta from "./components/metaComponent.js";


function App() {
  var currentTab
  // initialize a browser router
  const router = createBrowserRouter([{
    // parent route component
    element: <Layout />,

    // 404 Page Not Found component
    // errorElement: <Page404 />,

    // child route components 
    children: [
      { 
        path: "/Features/Setups", 
        element: (
          <>
            <Meta title="VSBL - Setups" description="Save time setting up the shift. Train Shift Leaders more easily and run more consistent shifts, no matter who is in charge." />
            <Setups />
          </>  
        )
      },
      { 
        path: "/Features/Chat", 
        element: (
          <>
            <Meta title="VSBL - Chat" description="Share updates, provide instant feedback, and coordinate tasks effortlessly with our chat feature. It ensures efficient communication, keeping your team connected and engaged with just a few taps." />
            <Chat />
          </>  
        )
      },
      { 
        path: "/Features/Training", 
        element: (
          <>
            <Meta title="VSBL - Training" description="Restaurant life is hard enough, training your people should not be. Quickly assign training plans, monitor progress, and provide feedback—all with ease." />
            <Training />
          </>  
        )
      },
      { 
        path: "/Pricing", 
        element: (
          <>
            <Meta title="VSBL - Pricing" description="VSBL Pricing and Plans" />
            <Pricing />
          </>  
        )
      },
      { path: "/", element: (
          <>
            <Meta title="VSBL" description="Changing the way people experience the restaurant" />
            <Home /> 
          </>
        )
      },
      { path: "/Features/Reporting", element: 
        (
          <>
            <Meta title="VSBL - Reporting" description="Visibility into your team and operations provides insights that informs your weekly meetings" />
            <Reporting />
          </>  
        )  
      },
      { path: "/Features/Shifts", element: 
        (
          <>
            <Meta title="VSBL - Shifts" description="Make running shifts easier and more consistent, delivering tasks directly to your team, giving everyone autonomy to do their job." />
            <Shifts /> 
          </>
        )
      },
      { path: "/Features/Home-Screen", element: 
        (
          <>
            <Meta title="VSBL - Home Screen" description="Equip every person on your team with the tools and information needed to do their job well" />
            <FeaturesHome /> 
          </>
        )    
      },
      { path: "/Features/Vendors", element: 
        (
          <>
            <Meta title="VSBL - Vendors" description="Quick access to vendors for your entire team" />
            <Vendors /> 
          </>
        )
      },
      { path: "/Features/Accountability", element: 
        (
          <>
            <Meta title="VSBL - Accountability" description="Know when to have important conversations and gain peace of mind with better documentation" />
            <Accountability /> 
          </>
        )
      },
      { path: "/Features/Checklists-and-Tasks", element: 
        (
          <>
            <Meta title="VSBL - Checklists and Tasks" description="Easily create and complete checklists. Pull out your phone and edit away." />
            <ChecklistsAndTasks /> 
          </>
        )
      },
      { path: "/Features/Waste", element: 
        (
          <>
            <Meta title="VSBL - Waste" description="Realtime capture provides insights for better decision making." />
            <Waste />
          </>
        )
      },
      { path: "/Features/Team-and-Scoreboard", element: 
        (
          <>
            <Meta title="VSBL - Team and Scoreboard" description="Team Feed shows you what’s going on throughout the day, but also allows team members to interact together, celebrating wins and staying in-the-know" />
            <TeamAndScoreboard /> 
          </>
        )
      },
      { path: "/Features/Rewards", element: 
        (
          <>
            <Meta title="VSBL - Team and Scoreboard" description="Team Feed shows you what’s going on throughout the day, but also allows team members to interact together, celebrating wins and staying in-the-know" />
            <Rewards /> 
          </>
        )
      },
    ]
  }])

  return (
    <HelmetProvider>
      <RouterProvider router={router} />
    </HelmetProvider>
  );
}

export default App;