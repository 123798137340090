const PathConstants = { 
    Home: "/",
    Testimonials: "/#testimonials",
    Pricing: "/Pricing/#top",
    Login: "https://portal.vsblapp.com/login",
    featureHome: "/Features/Home-Screen/#top",
    SignUp: "https://portal.vsblapp.com/signup",
    Reporting: "/Features/Reporting/#top",
    Shifts: "/Features/Shifts/#top",
    Vendors: "/Features/Vendors/#top",
    Accountability: "/Features/Accountability/#top",
    ChecklistsAndTasks: "/Features/Checklists-and-Tasks/#top",
    Waste: "/Features/Waste/#top",
    Setups: "/Features/Setups/#top",
    TeamAndScoreboard: "/Features/Team-and-Scoreboard/#top",
    Rewards: "/Features/Rewards/#top",
    Training: "/Features/Training/#top",
    Chat: "/Features/Chat/#top"
}

export default PathConstants