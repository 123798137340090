import Header from '../../components/Header.js';
import NonHomeTopHeroUnit from '../../components/NonHomeTopHeroUnit.js';
import LeftFacingHeroBlock from '../../components/LeftFacingHeroBlock.js';
import RightFacingHeroBlock from '../../components/RightFacingHeroBlock.js';
import global from '../../css/Global.module.css';
import Footer from '../../components/Footer.js';
import topHeroUnitImage from '../../bin/images/setups_topHeroUnit.png';
import heroBlockImage1 from '../../bin/images/setups_setupTemplates.png';
import heroBlockImage2 from '../../bin/images/setups_autoAssigned.png';
import heroBlockImage3 from '../../bin/images/setups_breakManagement.png';
import heroBlockImage4 from '../../bin/images/setups_scheduleIntegration.png';
import heroBlockImage5 from '../../bin/images/setups_tags.png'; 

function Setups() {
  return (
    <div className={global.contentBody}>
      <NonHomeTopHeroUnit 
        imgSrc='/bin/images/vsbl_background.png'
        titleTag='SETUPS'
        title='Time to Throw Away The Whiteboard'
        titleIntroParagraph='Save time setting up the shift. Train Shift Leaders more easily and run more consistent shifts, no matter who is in charge.'
        titleImg={topHeroUnitImage}
      />

      <LeftFacingHeroBlock 
        imgSrc='imgSrc="/bin/images/setupTemplates.png"' 
        featureTag="SETUP TEMPLATES" 
        featureTitle="Simplify &amp; Streamline your Shifts" 
        featureBlurb='Build out setup times and positions with staffing numbers - specific to each day of the week. Your leaders will know what they need to build automatically every day.'
        titleImg={heroBlockImage1}
      />

      <RightFacingHeroBlock 
        imgSrc='imgSrc="/bin/images/setupTemplates.png"' 
        featureTag="AUTO-ASSIGNED" 
        featureTitle="Delivers To-Dos Based on Position" 
        featureBlurb='VSBL knows what needs to be completed and gives Checklists &amp; Tasks to your team every day. Based on where each employee works, those Checklists &amp; Tasks will be assigned to employees right on their Home Screen.'
        titleImg={heroBlockImage2}
      />

      <LeftFacingHeroBlock 
        imgSrc='imgSrc="/bin/images/setupTemplates.png"' 
        featureTag="BREAK MANAGEMENT" 
        featureTitle="Your Breaks, Your Way" 
        featureBlurb='Establish your restaurant’s break rules - with each employee’s schedule, we let you know who needs to go on break and when (bonus: people get break timers and notifications right on their phone)'
        titleImg={heroBlockImage3}
      />

        <RightFacingHeroBlock 
          imgSrc='imgSrc="/bin/images/setupTemplates.png"' 
          featureTag="SCHEDULE INTEGRATION" 
          featureTitle="Save Time, Know Who’s Available" 
          featureBlurb='You build the schedule and we put it in the app for you. Know who’s going to be working when and have them automatically show in the Setup Roster. Edit employee’s schedules without the need to adjust in your scheduling provider.'
          titleImg={heroBlockImage4}
        />

        <LeftFacingHeroBlock 
          imgSrc='imgSrc="/bin/images/setupTemplates.png"' 
          featureTag="TAGS"
          featureTitle="Quick Context About Your People" 
          featureBlurb='Who called out? Who’s training? Who’s under 18 and can’t slice tomatoes? Has Jason been on Window four times today? All these questions are answered at a glance with tags.'
          titleImg={heroBlockImage5}
        />
    </div>
  );
}

export default Setups;


