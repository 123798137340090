import Header from '../components/Header.js';
import NonHomeTopHeroUnit from '../components/NonHomeTopHeroUnit.js';
import LeftFacingHeroBlock from '../components/LeftFacingHeroBlock.js';
import RightFacingHeroBlock from '../components/RightFacingHeroBlock.js';
import global from '../css/Global.module.css';
import Footer from '../components/Footer.js';
import topHeroUnitImage from '../bin/images/teamFeed_topHeroUnit.png';
import heroBlockImage1 from '../bin/images/teamFeed_interact.png';
import heroBlockImage2 from '../bin/images/teamFeed_broadcasts.png';
import heroBlockImage3 from '../bin/images/teamFeed_birthdaysAndAnniversaries.png';
import heroBlockImage4 from '../bin/images/teamFeed_scoreboard.png';
import PathConstants from '../routes/pathConstants.js';

const features = [
  { header: 'Accountability' },
  'Start using immediately with template data',
  'Permission locked',
  'Easy-to-use Point system',
  'Dashboard for visibility',
  'Users you select will be notified when needed',
  'Enforce or Defer Disciplinary Actions',
  'Assign single Disciplinary Action',
  'Customizable Accountability Period',
  'Export User’s full Accountability history on demand',
  'Accountability Reporting',
  'Team Member visibility into their own Accountability',
  { header: 'Break Management' },
  'Customizable Break rules',
  'Suggested run times',
  'Break timer on User’s home screen',
  'Push notifications when User is sent on break, has 5 minutes left on break, and when it’s completed',
  'Waive breaks when needed',
  { header: 'Chat' },
  'Create custom Channels',
  'Add Users, Roles, or Teams to a Channel',
  '@Mentions',
  'React to messages',
  'Users will receive push notifications',
  'Profanity Filter',
  { header: 'Checklists & Tasks' },
  'Start using immediately with Template data',
  'Auto-assign to Users via Shifts',
  'Collaborative via Teams',
  'Private Tasks',
  'Upload a picture when assigning a Task',
  'Option for image requirement for completion on Tasks',
  'Group and Individual assignment for Checklists',
  'Add links to Checklists and Tasks',
  'Checklist Reporting',
  'Deactivate and Activate Checklists',
  'View Checklist history in multiple places',
  'Share Checklists between stores on vsbl',
  { header: 'Rewards' },
  'Start using immediately with Template data',
  'Users manage their own Tokens',
  'See Token earning history',
  'Easy on Leadership (just deliver Rewards when redeemed)',
  'Tokens automatically distribute to Users',
  'Users you select will be notified when Reward is redeemed',
  'Users can share Tokens',
  'Users earn tokens when chosen as Top Performer or Recognized',
  'View past Rewards',
  { header: 'Shifts' },
  'Start using immediately with Template data',
  'Easy-to-use drag & drop system for setups',
  'Add Buddies on a Setup to pair Users together',
  'Copy from the previous Setup',
  'Add or remove Users from a Setup',
  'Add Users to multiple Positions on one Setup',
  'Checklists & Tasks auto assign to positions',
  'Setup tags show who’s a minor or in training',
  'Users can easily pick-up To-Dos as needed',
  'Communicate with the team via Schedule Notes',
  'Track Safe Counts',
  'Enter Shift Notes to be seen by other Leaders',
  'View your Schedule',
  'Track Breaks',
  'PIN sign-in on tablet',
  'Users can complete To-Dos on tablet',
  { header: 'Training' },
  'Compile courses into Training Plans',
  'Assign Training Plans with a tap',
  'Training tile on Home Screen for trainee',
  'Training tile on Home Screen for Trainer when training',
  'Assign Trainers and Training Dates',
  'Trainers can give private feedback',
  'Training dashboard to view progress and feedback',
  'Add links to courses',
  'Shift Leaders see training in Shifts',
  'Assign Trainers day-of if needed',
  { header: 'Vendors' },
  'Easily Add & Remove',
  'Quick action buttons:',
  'Email',
  'Phone Call',
  'Text',
  'Add notes about a Vendor',
  'Search the Vendor List',
  { header: 'Waste Tracking' },
  'Start using immediately with Template data',
  'Real-Time Capture at the source',
  'Record by count or weight',
  'Reason codes can be used to track reasons for Waste',
  'Track items for donations',
  'Multiple Waste Categories',
  'Leaders tap a button to get a Waste report each daypart',
];

const renderFeatureTable = () => {

  return (
    <div className={global.featureTable}>
      {features.map((item, index) => 
        item.header ? (
          <div className={global.pricingHeaderRow} key={index}>
            <span>{item.header}</span>
          </div>
        ) : (
          <div className={global.pricingFeatureRow} key={index}>
            <span>{item}</span>
          </div>
        )
      )}
    </div>
  ); 
};

function Pricing() {
  return (
    <div className={[global.contentBody, global.pricingPage].join(' ')}> 
      <NonHomeTopHeroUnit 
        imgSrc='/bin/images/vsbl_background.png'
      />

      <div className={[global.homeh2, global.plan].join(' ')}>
        <h2>Choose a plan</h2>
        <p>No contracts. No hidden fees. Cancel anytime. </p>
      </div> 

      <div className={global.cards_container}>
        <div className={global.masterCardsContainer}>
          <div className={[global.card, global.waste].join(' ')}>
            <div className={global.card_inner_top}>
              <div className={global.logo}>
                <div className={global.annualCard}>Monthly</div>
              </div>
              <div className={global.service_description}>Unlock visibility into your People &#38; Operations with the entire VSBL ecosystem.</div>
              <div className={global.service_price}><span className={global.service_large_price}>$249.99</span> / month +Tax</div>
            </div>
          <div className={global.card_inner_bottom}>
              <ul>
                  <li className={global.included}><svg height="1em" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7.5" r="7" fill="#07D0B1"/><path d="M4.66602 6.98235L6.53268 9.05642L9.33268 5.94531" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>Accountability</li>
                  <li className={global.included}><svg height="1em" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7.5" r="7" fill="#07D0B1"/><path d="M4.66602 6.98235L6.53268 9.05642L9.33268 5.94531" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>Break Management</li>
                  <li className={global.included}><svg height="1em" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7.5" r="7" fill="#07D0B1"/><path d="M4.66602 6.98235L6.53268 9.05642L9.33268 5.94531" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>Chat <span className={global.pricingNewLabel}>New!</span></li>
                  <li className={global.included}><svg height="1em" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7.5" r="7" fill="#07D0B1"/><path d="M4.66602 6.98235L6.53268 9.05642L9.33268 5.94531" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>Checklists &amp; Tasks</li>
                  <li className={global.included}><svg height="1em" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7.5" r="7" fill="#07D0B1"/><path d="M4.66602 6.98235L6.53268 9.05642L9.33268 5.94531" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>Rewards</li>
                  <li className={global.included}><svg height="1em" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7.5" r="7" fill="#07D0B1"/><path d="M4.66602 6.98235L6.53268 9.05642L9.33268 5.94531" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>Reporting</li>
                  <li className={global.included}><svg height="1em" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7.5" r="7" fill="#07D0B1"/><path d="M4.66602 6.98235L6.53268 9.05642L9.33268 5.94531" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>Shifts</li>
                  <li className={global.included}><svg height="1em" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7.5" r="7" fill="#07D0B1"/><path d="M4.66602 6.98235L6.53268 9.05642L9.33268 5.94531" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>Training <span className={global.pricingNewLabel}>New!</span></li>
                  <li className={global.included}><svg height="1em" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7.5" r="7" fill="#07D0B1"/><path d="M4.66602 6.98235L6.53268 9.05642L9.33268 5.94531" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>Vendors</li>
                  <li className={global.included}><svg height="1em" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7.5" r="7" fill="#07D0B1"/><path d="M4.66602 6.98235L6.53268 9.05642L9.33268 5.94531" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>Waste Tracking</li>
              </ul>
              <div className={global.vsbl_get_started}>
                  <div className={[global.get_started, global.vsbl_get_started_button].join(' ')}><a href={PathConstants.SignUp}>Sign Up</a></div>
              </div>
          </div>
        </div>
        <div className={[global.card, global.vsbl_service].join(' ')}>
          <div className={global.most_popular}>Most Popular</div>
            <div className={global.card_inner_top}>
                <div className={global.logo}>
                  <div className={global.logoInner}>
                    <div className={global.annualCard}>Annual</div>
                    <div className={global.discountBox}>
                    <div className={global.discountBoxInner}>
                      <span>10% Off</span>
                    </div>
                  </div>
                </div>
                </div>
                <div className={global.service_description}>The entire VSBL ecosystem, all available to you at a discounted annual price.</div>
                <div className={global.promotionalPricing}>
                  <div className={[global.service_price, global.promotionalServicePrice].join(' ')}><span className={global.topServicePrice}><span className={global.service_price_promotion}>$224.99</span> / month +Tax</span></div>
                </div>
            </div>
            <div className={global.card_inner_bottom}>
              <ul>
                <li className={global.included}><svg height="1em" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7.5" r="7" fill="#07D0B1"/><path d="M4.66602 6.98235L6.53268 9.05642L9.33268 5.94531" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>Accountability</li>
                <li className={global.included}><svg height="1em" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7.5" r="7" fill="#07D0B1"/><path d="M4.66602 6.98235L6.53268 9.05642L9.33268 5.94531" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>Break Management</li>
                <li className={global.included}><svg height="1em" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7.5" r="7" fill="#07D0B1"/><path d="M4.66602 6.98235L6.53268 9.05642L9.33268 5.94531" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>Chat <span className={global.pricingNewLabel}>New!</span></li>
                <li className={global.included}><svg height="1em" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7.5" r="7" fill="#07D0B1"/><path d="M4.66602 6.98235L6.53268 9.05642L9.33268 5.94531" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>Checklists &amp; Tasks</li>
                <li className={global.included}><svg height="1em" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7.5" r="7" fill="#07D0B1"/><path d="M4.66602 6.98235L6.53268 9.05642L9.33268 5.94531" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>Rewards</li>
                <li className={global.included}><svg height="1em" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7.5" r="7" fill="#07D0B1"/><path d="M4.66602 6.98235L6.53268 9.05642L9.33268 5.94531" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>Reporting</li>
                <li className={global.included}><svg height="1em" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7.5" r="7" fill="#07D0B1"/><path d="M4.66602 6.98235L6.53268 9.05642L9.33268 5.94531" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>Shifts</li>
                <li className={global.included}><svg height="1em" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7.5" r="7" fill="#07D0B1"/><path d="M4.66602 6.98235L6.53268 9.05642L9.33268 5.94531" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>Training <span className={global.pricingNewLabel}>New!</span></li>
                <li className={global.included}><svg height="1em" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7.5" r="7" fill="#07D0B1"/><path d="M4.66602 6.98235L6.53268 9.05642L9.33268 5.94531" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>Vendors</li>
                <li className={global.included}><svg height="1em" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7.5" r="7" fill="#07D0B1"/><path d="M4.66602 6.98235L6.53268 9.05642L9.33268 5.94531" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/></svg>Waste Tracking</li>
              </ul>
              <div className={global.vsbl_get_started}>
                  <div className={[global.get_started, global.vsbl_get_started_button].join(' ')}><a href={PathConstants.SignUp}>Sign Up</a></div>
              </div>
            </div>
          </div> 
        </div>
      </div>

      <div className={global.pricingFeaturesExplainH2}>
        <h2>What comes with VSBL?</h2>
      </div>

      <div className={global.featuresTableContainer}>
        {renderFeatureTable()}
      </div>
    </div>
  );
}

export default Pricing;


