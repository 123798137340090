import WideNonHomeTopHeroUnit from '../../components/WideNonHomeTopHeroUnit.js';
import WideLeftFacingHeroBlock from '../../components/WideLeftFacingHeroBlock.js';
import WideRightFacingHeroBlock from '../../components/WideRightFacingHeroBlock.js';
import ComingSoonHeroBlock from '../../components/ComingSoonHeroBlock.js';
import global from '../../css/Global.module.css';
import topHeroUnitImage from '../../bin/images/reporting_topHeroUnit.png';
import heroBlockImage1 from '../../bin/images/reporting_accountability.png';
import heroBlockImage2 from '../../bin/images/reporting_checklists.png';
import heroBlockImage3 from '../../bin/images/reporting_waste.png';


function Reporting() {
  return (
    <div className={global.contentBody}>
      <WideNonHomeTopHeroUnit 
        imgSrc='/bin/images/vsbl_background.png'
        titleTag='REPORTING'
        title='More Insights, Better Decisions'
        titleIntroParagraph='Visibility into your team and operations provides insights that informs your weekly meetings'
        titleImg={topHeroUnitImage} 
      />

      <WideLeftFacingHeroBlock 
        featureTag="ACCOUNTABILITY" 
        featureTitle="Identify Patterns of Behavior" 
        featureBlurb='See which infractions are being sent most by your leaders, which days they’re happening, and keep an eye on those with the most infraction points.'
        titleImg={heroBlockImage1}
      />

      <WideRightFacingHeroBlock 
        featureTag="CHECKLISTS" 
        featureTitle="Completion and Question Search" 
        featureBlurb='Who’s completing checklists better: Front or Kitchen? Which checklists are being completed most often? Wondering if a task is on a checklist somewhere? Search every question on every checklist to find out.'
        titleImg={heroBlockImage2}
      />

      <WideLeftFacingHeroBlock 
        featureTag="WASTE" 
        featureTitle="Real Time Tracking = Pinpoint Opportunities" 
        featureBlurb='Tracking waste at the source shows you where and when it happened, including everything thrown away and donated. Know which items you’re wasting the most and what time of day has the greatest opportunity.'
        titleImg={heroBlockImage3}
      />

      <ComingSoonHeroBlock 
        featureTag="SHIFTS [COMING SOON]" 
        featureTitle="Track Effectiveness of Shifts &amp; Shift Leaders" 
        featureBlurb='What’s the dream? Anything that helps shifts run easier, smoother, and more consistent. But what if you knew which Shift Leaders were running the best shifts too? We’re excited to help you make and shape better leaders along the way.'
      
      />
    </div>
  );
}

export default Reporting;


