import NonHomeTopHeroUnit from '../../components/NonHomeTopHeroUnit.js';
import LeftFacingHeroBlock from '../../components/LeftFacingHeroBlock.js';
import RightFacingHeroBlock from '../../components/RightFacingHeroBlock.js';
import global from '../../css/Global.module.css';
import topHeroUnitImage from '../../bin/images/Chat_Section.png';
import heroBlockImage1 from '../../bin/images/Chat_Create.png';
import heroBlockImage2 from '../../bin/images/Chat_Section.png';
import heroBlockImage3 from '../../bin/images/Chat_React.png';

function Chat() { 
  return (
    <div className={global.contentBody}>
      <NonHomeTopHeroUnit 
        imgSrc='/bin/images/vsbl_background.png'
        titleTag='CHAT'
        title='Communication at your fingertips'
        titleIntroParagraph='Share updates, provide instant feedback, and coordinate tasks effortlessly with our chat feature. It ensures efficient communication, keeping your team connected and engaged with just a few taps.'
        titleImg={topHeroUnitImage}
      />

      <LeftFacingHeroBlock 
        featureTag="CREATE" 
        featureTitle="Custom chats for your convenience" 
        featureBlurb='Create different Chat Channels that you can add Users, Roles, or Teams to. Enable the Profanity filter to give yourself peace of mind.'
        titleImg={heroBlockImage1}
      />

      <RightFacingHeroBlock 
        featureTag="CHAT" 
        featureTitle="Easy communication at your fingertips" 
        featureBlurb='Message your team, send images, or reply in real-time. We’ll alert your team with a push notification!'
        titleImg={heroBlockImage2}
      />

      <LeftFacingHeroBlock 
        featureTag="REACT" 
        featureTitle="Express your thoughts" 
        featureBlurb='React to a message to let everyone know how you feel, or type it out by sending a reply. Did autocorrect fail you? Edit your messages as needed 🙂'
        titleImg={heroBlockImage3}
      />
    </div>
  );
}

export default Chat;


