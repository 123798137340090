import * as React from 'react';
import global from '../css/Global.module.css';
import setupTemplates from '../bin/images/setupTemplates.png';
import { NavLink, BrowserRouter as Router } from 'react-router-dom';
import PathConstants from '../routes/pathConstants.js';

import shiftsFeatureDisplayBoxImg from '../bin/images/shifts_topHeroUnit.png';
import setupsFeatureDisplayBoxImg from '../bin/images/setups_topHeroUnit.png';
import accountabilityFeatureDisplayBoxImg from '../bin/images/accountability_topHeroUnit.png';
import vendorsFeatureDisplayBoxImg from '../bin/images/vendors_topHeroUnit.png';
import reportingFeatureDisplayBoxImg from '../bin/images/reporting_topHeroUnit.png';
import rewardsFeatureDisplayBoxImg from '../bin/images/rewards_topHeroUnit.png';
import checklistsAndTasksFeatureDisplayBoxImg from '../bin/images/checklistsAndTasks_topHeroUnit.png';
import wasteFeatureDisplayBoxImg from '../bin/images/waste_topHeroUnit.png';
import homeScreenFeatureDisplayBoxImg from '../bin/images/homeScreen_topHeroUnit.png';
import teamAndScoreboardFeatureDisplayBoxImg from '../bin/images/teamFeed_topHeroUnit.png';

function FeatureDisplayBoxInner(props) { 
    let displayBoxShifts = [];
    let displayBoxSetups = [];
    let displayBoxAccountability = [];
    let displayBoxVendors = [];
    let displayBoxReporting = [];
    let displayBoxRewards = [];
    let displayBoxChecklistsAndTasks = [];
    let displayBoxWaste = [];
    let displayBoxHomeScreen = [];
    let displayTeamAndScoreboard = [];

    if(props.activeTab == "shifts"){displayBoxShifts.push(global.activeFeatureBox);}else
    if(props.activeTab == "setups"){displayBoxSetups.push(global.activeFeatureBox);}else
    if(props.activeTab == "accountability"){displayBoxAccountability.push(global.activeFeatureBox);}else
    if(props.activeTab == "vendors"){displayBoxVendors.push(global.activeFeatureBox);}else
    if(props.activeTab == "reporting"){displayBoxReporting.push(global.activeFeatureBox);}else
    if(props.activeTab == "rewards"){displayBoxRewards.push(global.activeFeatureBox);}else
    if(props.activeTab == "checklistsAndTasks"){displayBoxChecklistsAndTasks.push(global.activeFeatureBox);}else
    if(props.activeTab == "waste"){displayBoxWaste.push(global.activeFeatureBox);}else
    if(props.activeTab == "homeScreen"){displayBoxHomeScreen.push(global.activeFeatureBox);}else
    if(props.activeTab == "teamAndScoreboard"){displayTeamAndScoreboard.push(global.activeFeatureBox);}

    return (
        <div className={global.displayBoxContainer}>
            <div className={[displayBoxShifts, global.displayBoxContainerInner].join(' ')}>
                <div className={global.displayBoxContentLeft}>
                    <div className={global.displayBoxTitle}>Everything, Everyday, One Screen</div>
                    <div className={global.displayBoxText}>Literally. Make running shifts easier and more consistent, delivering tasks directly to your team, giving everyone autonomy to do their job.</div>
                    <div className={global.displayBoxLearnMore}><a href={PathConstants.Shifts}>Learn More</a></div>
                </div>
                <div className={global.displayBoxContentRight}><img src={shiftsFeatureDisplayBoxImg} /></div>
            </div>
            <div className={[displayBoxSetups, global.displayBoxContainerInner].join(' ')}>
                <div className={global.displayBoxContentLeft}>
                    <div className={global.displayBoxTitle}>Time to Throw Away The Whiteboard</div>
                    <div className={global.displayBoxText}>Save time setting up the shift. Train Shift Leaders more easily and run more consistent shifts, no matter who is in charge.</div>
                    <div className={global.displayBoxLearnMore}><a href={PathConstants.Setups}>Learn More</a></div>
                </div>
                <div className={global.displayBoxContentRight}><img src={setupsFeatureDisplayBoxImg} /></div>
            </div>
            <div className={[displayBoxAccountability, global.displayBoxContainerInner].join(' ')}>
                <div className={global.displayBoxContentLeft}>
                    <div className={global.displayBoxTitle}>Coaching &amp; Discipline Made Easy</div>
                    <div className={global.displayBoxText}>Know when to have important conversations and gain peace of mind with better documentation</div>
                    <div className={global.displayBoxLearnMore}><a href={PathConstants.Accountability}>Learn More</a></div>
                </div>
                <div className={global.displayBoxContentRight}><img src={accountabilityFeatureDisplayBoxImg} /></div>
            </div>
            <div className={[displayBoxVendors, global.displayBoxContainerInner].join(' ')}>
                <div className={global.displayBoxContentLeft}>
                    <div className={global.displayBoxTitle}>Vendor Contact Directory</div>
                    <div className={global.displayBoxText}>Quick access to vendors for your entire team</div>
                    <div className={global.displayBoxLearnMore}><a href={PathConstants.Vendors}>Learn More</a></div>
                </div>
                <div className={global.displayBoxContentRight}><img src={vendorsFeatureDisplayBoxImg} /></div>
            </div>
            <div className={[displayBoxReporting, global.displayBoxContainerInner].join(' ')}>
                <div className={global.displayBoxContentLeft}>
                    <div className={global.displayBoxTitle}>More Insights, Better Decisions</div>
                    <div className={global.displayBoxText}>Visibility into your team and operations provides insights that informs your weekly meetings</div>
                    <div className={global.displayBoxLearnMore}><a href={PathConstants.Reporting}>Learn More</a></div>
                </div>
                <div className={global.displayBoxContentRight}><img src={reportingFeatureDisplayBoxImg} /></div>
            </div>
            <div className={[displayBoxRewards, global.displayBoxContainerInner].join(' ')}>
                <div className={global.displayBoxContentLeft}>
                    <div className={global.displayBoxTitle}>Increased Engagement through Rewards</div>
                    <div className={global.displayBoxText}>When people are excited about their job, they bring their best self to the table</div>
                    <div className={global.displayBoxLearnMore}><a href={PathConstants.Rewards}>Learn More</a></div>
                </div>
                <div className={global.displayBoxContentRight}><img src={rewardsFeatureDisplayBoxImg} /></div>
            </div>
            <div className={[displayBoxChecklistsAndTasks, global.displayBoxContainerInner].join(' ')}>
                <div className={global.displayBoxContentLeft}>
                    <div className={global.displayBoxTitle}>Build &amp; Collaborate</div>
                    <div className={global.displayBoxText}>Easily create and complete checklists. Pull out your phone and edit away.</div>
                    <div className={global.displayBoxLearnMore}><a href={PathConstants.ChecklistsAndTasks}>Learn More</a></div>
                </div>
                <div className={global.displayBoxContentRight}><img src={checklistsAndTasksFeatureDisplayBoxImg} /></div>
            </div>
            <div className={[displayBoxWaste, global.displayBoxContainerInner].join(' ')}>
                <div className={global.displayBoxContentLeft}>
                    <div className={global.displayBoxTitle}>Keep Track of Waste and Donations</div>
                    <div className={global.displayBoxText}>Realtime capture provides insights for better decision making.</div>
                    <div className={global.displayBoxLearnMore}><a href={PathConstants.Waste}>Learn More</a></div>
                </div>
                <div className={global.displayBoxContentRight}><img src={wasteFeatureDisplayBoxImg} /></div>
            </div>
            <div className={[displayBoxHomeScreen, global.displayBoxContainerInner].join(' ')}>
                <div className={global.displayBoxContentLeft}>
                    <div className={global.displayBoxTitle}>Everything I Need to Do Today</div>
                    <div className={global.displayBoxText}>Equip every person on your team with the tools and information needed to do their job well</div>
                    <div className={global.displayBoxLearnMore}><a href={PathConstants.featureHome}>Learn More</a></div>
                </div>
                <div className={global.displayBoxContentRight}><img src={homeScreenFeatureDisplayBoxImg} /></div>
            </div>
            <div className={[displayTeamAndScoreboard, global.displayBoxContainerInner].join(' ')}>
                <div className={global.displayBoxContentLeft}>
                    <div className={global.displayBoxTitle}>Communications &amp; Scoreboard</div>
                    <div className={global.displayBoxText}>Team Feed shows you what’s going on throughout the day, but also allows team members to interact together, celebrating wins and staying in-the-know</div>
                    <div className={global.displayBoxLearnMore}><a href={PathConstants.TeamAndScoreboard}>Learn More</a></div>
                </div>
                <div className={global.displayBoxContentRight}><img src={teamAndScoreboardFeatureDisplayBoxImg} /></div>
            </div>
        </div>
    );
}

export default FeatureDisplayBoxInner;