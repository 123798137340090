import NonHomeTopHeroUnit from '../../components/NonHomeTopHeroUnit.js';
import LeftFacingHeroBlock from '../../components/LeftFacingHeroBlock.js';
import RightFacingHeroBlock from '../../components/RightFacingHeroBlock.js';
import global from '../../css/Global.module.css';
import topHeroUnitImage from '../../bin/images/checklistsAndTasks_topHeroUnit.png';
import heroBlockImage1 from '../../bin/images/checklistsAndTasks_completing.png';
import heroBlockImage2 from '../../bin/images/checklistsAndTasks_buildingAndEditing.png';
import heroBlockImage3 from '../../bin/images/checklistsAndTasks_quickTasks.png';
import heroBlockImage4 from '../../bin/images/checklistsAndTasks_reporting.png';

function ChecklistsAndTasks() {
  return (
    <div className={global.contentBody}>
      <NonHomeTopHeroUnit 
        imgSrc='/bin/images/vsbl_background.png'
        titleTag='CHECKLISTS AND TASKS'
        title='Build &amp; Collaborate'
        titleIntroParagraph='Easily create and complete checklists. Pull out your phone and edit away.'
        titleImg={topHeroUnitImage} 
      />

      <LeftFacingHeroBlock 
        imgSrc='imgSrc="/bin/images/setupTemplates.png"' 
        featureTag="COMPLETING" 
        featureTitle="Auto-Assigned and Collaborative" 
        featureBlurb='When assigned to positions, people automatically receive their checklists and tasks (and a push notification). Require pictures and signatures for better verification. Collaborate seamlessly, ensuring everyone can contribute.'
        titleImg={heroBlockImage1}
      />

      <RightFacingHeroBlock 
        imgSrc='imgSrc="/bin/images/setupTemplates.png"' 
        featureTag="BUILDING &amp; EDITING" 
        featureTitle="Craft checklists anywhere, anytime" 
        featureBlurb='A well-oiled machine needs to be oiled regularly; edit checklists from your phone (as well as desktop) to make changes when you think of them. Keep your system up-to-date so it doesn’t get rusty.'
        titleImg={heroBlockImage2}
      />

      <LeftFacingHeroBlock 
        imgSrc='imgSrc="/bin/images/setupTemplates.png"' 
        featureTag="QUICK TASKS" 
        featureTitle="Spot something that needs attention?" 
        featureBlurb='Create tasks instantly and assign them to any team member. Keep your workflow smooth and responsive. And remember, we’ll remind you about anything you’ve assigned to others on your home screen.'
        titleImg={heroBlockImage3}
      />

      <RightFacingHeroBlock 
        imgSrc='imgSrc="/bin/images/setupTemplates.png"' 
        featureTag="REPORTING" 
        featureTitle="Gain real-time insights" 
        featureBlurb='Track your team’s checklist progress in real-time. Never wonder “Is this on a checklist?” — find answers easily with our Search Questions feature.'
        titleImg={heroBlockImage4}
      />
    </div>
  );
}

export default ChecklistsAndTasks;


