import * as React from 'react';
import global from '../css/Global.module.css';
import setupTemplates from '../bin/images/setupTemplates.png';
import { NavLink, BrowserRouter as Router } from 'react-router-dom';
/*import rewards_video from 'https://www.youtube.com/embed/HKMEmlFUR1s';*/

function RightFacingHeroBlock(props) { 
    return ( 
        <div className={[global.monolith, global.rightImgMonolith].join(' ')}>
            <div className={global.monolithInner}>
                <div className={global.featureOverview}>
                    <div className={global.featureTag}>{props.featureTag}</div>
                    <div className={global.featureTitle}>{props.featureTitle}</div>
                    <p className={global.featureBlurb}>{props.featureBlurb}</p>
                </div>
                <div className={global.imageContainer}>
                <iframe
                    width="380"
                    height="476"
                    src="https://www.youtube.com/embed/HKMEmlFUR1s"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen>
                </iframe>
                </div>
            </div>
        </div>
    );
}

export default RightFacingHeroBlock;